import './locales/i18n';

import 'simplebar/src/simplebar.css';

import 'react-lazy-load-image-component/src/effects/blur.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { SettingsProvider } from './components/settings';
import ScrollToTop from './components/scroll-to-top';

import { store } from './store';

import reportWebVitals from './reportWebVitals';
import App from './App';

import { QueryClient, QueryClientProvider } from 'react-query';
import Geocode from 'react-geocode';
import './index.css';

Geocode.setApiKey(process.env.REACT_APP_MAP_KEY || '');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </SettingsProvider>
      </HelmetProvider>
    </Provider>
  </QueryClientProvider>
);

serviceWorkerRegistration.register();

reportWebVitals();
